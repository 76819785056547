import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation } from '@reach/router';

import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import StartTrialPage from 'src/components/shared/StartTrialPage';
import { last } from 'lodash';

const connector = connect((state: IState) => ({
    services: PvolveSelectors.config.services(state),
    shopifyUrl: PvolveSelectors.config.shopifyUrl(state),
}));

const FreeTrial = ({ shopifyUrl, services }: ConnectedProps<typeof connector>) => {
    const trialPages = services?.subscription?.trialPages || {};
    const DEFAULT_SKU = '12month_trial7';

    const location = useLocation();
    const slug = last(location?.pathname.split('/')) || '';

    const selectedSku = trialPages[slug] || DEFAULT_SKU;

    return <StartTrialPage shopifyUrl={shopifyUrl} sku={selectedSku} />;
};

export default connector(FreeTrial);
